import './scss/style.scss';
import 'bootstrap';

import { WOW } from 'wowjs';
import { Swiper, Autoplay, Pagination } from 'swiper/js/swiper.esm.js';

Swiper.use([Autoplay, Pagination]);

/*Se inicializa el plugin para las animaciones*/
var wow = new WOW({
    live: false,
    scrollContainer: null
});

/*Todos los "load" cargan los bloques recurrentes*/
$('header').load("header.html");
$('footer').load("footer.html");

$('#block-promotions').load("block-promotions.html", function() {
    wow.init();
});

$('#block-contact-us').load("block-contact-us.html", function() {
    wow.init();
});

/*Script para el slider del home*/
if ($.contains(document.body, document.getElementById('slider-main'))) {

    var mySwiper = new Swiper('#slider-main', {
        loop: true,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
    });

    $(document).on('click', '.block-banner-main .btn-light', function(event) {
        event.preventDefault();

        var item = $(this).data('item');

        if (item) {
            mySwiper.slidePrev();
        } else {
            mySwiper.slideNext();
        }

    });
}

if ($.contains(document.body, document.getElementById('slider-our-partners'))) {

    var mySwiperOurPartners = new Swiper('#slider-our-partners', {
        slidesPerView: 10,
        spaceBetween: 69,
        autoplay: {
            delay: 5000,
        },
        loop: true,
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 2
            },
            // when window width is >= 840px
            840: {
                slidesPerView: 3
            },
            // when window width is >= 940px
            940: {
                slidesPerView: 4
            },
            // when window width is >= 120px
            1200: {
                slidesPerView: 6
            }
        }
    });

    $(document).on('click', '.block-our-partners .btn-light', function(event) {
        event.preventDefault();

        var item = $(this).data('item');

        if (item) {
            mySwiperOurPartners.slidePrev();
        } else {
            mySwiperOurPartners.slideNext();
        }

    });
}

if ($.contains(document.body, document.getElementById('slider-partners-one'))) {

    var mySwiperPartnersOne = new Swiper('#slider-partners-one', {
        slidesPerView: 10,
        spaceBetween: 60,
        autoHeight: true,
        autoplay: {
            delay: 5000,
        },
        loop: true,
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 2
            },
            // when window width is >= 840px
            840: {
                slidesPerView: 4
            }
        }
    });

    $(document).on('click', '.btn-partners-one', function(event) {
        event.preventDefault();

        var item = $(this).data('item');

        if (item) {
            mySwiperPartnersOne.slidePrev();
        } else {
            mySwiperPartnersOne.slideNext();
        }

    });
}

if ($.contains(document.body, document.getElementById('slider-partners-two'))) {

    var mySwiperPartnersTwo = new Swiper('#slider-partners-two', {
        slidesPerView: 10,
        spaceBetween: 60,
        autoHeight: true,
        autoplay: {
            delay: 5000,
        },
        loop: true,
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 2
            },
            // when window width is >= 840px
            840: {
                slidesPerView: 4
            }
        }
    });

    $(document).on('click', '.btn-partners-two', function(event) {
        event.preventDefault();

        var item = $(this).data('item');

        if (item) {
            mySwiperPartnersTwo.slidePrev();
        } else {
            mySwiperPartnersTwo.slideNext();
        }

    });
}

/*Se agregan las animaciones para toda la pagina que no cargan de menera recurrente*/
wow.init();